<template>
  <div class="container p-tb-2">
    <div class="row">
      <div class="col-lg-12">
        <div style="font-size: 16px; line-height: 1.5em; font-weight: 500">
          <h3>PRIVACY POLICY</h3>
          <p>
            Protecting your private information is our priority. This Statement of Privacy applies to
            https://vaporfly.com/ and governs data collection and usage. For the purposes of this Privacy Policy, unless
            otherwise noted, all references include https://vaporfly.com/ and Vaporfly. The Vaporfly website is an
            ecommerce site. By using the Vaporfly website, you consent to the data practices described in this
            statement.
          </p>
          <h3>COLLECTION OF YOUR PERSONAL INFORMATION</h3>
          <p>
            In order to better provide you with products and services offered on our Site, Vaporfly may collect
            personally identifiable information, such as your:
          </p>
          <ul>
            <li>First and Last Name</li>
            <li>Mailing Address</li>
            <li>E-mail Address</li>
            <li>Phone Number</li>
            <li>Company name</li>
          </ul>
          <p>
            If you purchase Vaporfly products and services, we require billing and credit card information. This
            information is used to complete the purchase transaction. Please keep in mind that if you directly disclose
            personally identifiable information or personally sensitive data through Vaporfly`s public message boards,
            this information may be collected and used by others. We do not collect any personal information about you
            unless you voluntarily provide it to us. However, you may be required to provide certain personal
            information to us when you elect to use certain products or services available on the Site. These may
            include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us
            or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an
            email message; (e) submitting your credit card or other payment information when ordering and purchasing
            products and services on our Site. To wit, we will use your information for, but not limited to,
            communicating with you in relation to services and/or products you have requested from us. We also may
            gather additional personal or non-personal information in the future.
          </p>
          <h3>USE OF YOUR PERSONAL INFORMATION</h3>
          <p>
            Vaporfly collects and uses your personal information to operate its website(s) and deliver the services you
            have requested. Vaporfly may also use your personally identifiable information to inform you of other
            products or services available from Vaporfly and its affiliates.
          </p>
          <h3>SHARING INFORMATION WITH THIRD PARTIES</h3>
          <p>Vaporfly does not sell, rent or lease its customer lists to third parties.</p>
          <p>
            Vaporfly may, from time to time, contact you on behalf of external business partners about a particular
            offering that may be of interest to you. In those cases, your unique personally identifiable information
            (e-mail, name, address, telephone number) is not transferred to the third party. Vaporfly may share data
            with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer
            support, or arrange for deliveries. All such third parties are prohibited from using your personal
            information except to provide these services to Vaporfly, and they are required to maintain the
            confidentiality of your information.
          </p>
          <p>
            Vaporfly may disclose your personal information, without notice, if required to do so by law or in the good
            faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
            process served on Vaporfly or the site; (b) protect and defend the rights or property of Vaporfly; and/or
            (c) act under exigent circumstances to protect the personal safety of users of Vaporfly, or the public.
          </p>
          <h3>TRACKING USER BEHAVIOR</h3>
          <p>
            Vaporfly may keep track of the websites and pages our users visit within Vaporfly, in order to determine
            what Vaporfly services are the most popular. This data is used to deliver customized content and advertising
            within Vaporflyg to customers whose behavior indicates that they are interested in a particular subject
            area.
          </p>
          <h3>AUTOMATICALLY COLLECTED INFORMATION</h3>
          <p>
            Information about your computer hardware and software may be automatically collected by Vaporfly. This
            information can include: your IP address, browser type, domain names, access times and referring website
            addresses. This information is used for the operation of the service, to maintain quality of the service,
            and to provide general statistics regarding use of the Vaporfly website.
          </p>
          <h3>USE OF COOKIES</h3>
          <p>
            The Vaporfly website may use “cookies” to help you personalize your online experience. A cookie is a text
            file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or
            deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server
            in the domain that issued the cookie to you.
          </p>
          <p>
            One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of
            a cookie is to tell the Web server that you have returned to a specific page. For example, if you
            personalize Vaporfly pages, or register with Vaporfly site or services, a cookie helps Vaporfly to recall
            your specific information on subsequent visits. This simplifies the process of recording your personal
            information, such as billing addresses, shipping addresses, and so on. When you return to the same Vaporfly
            website, the information you previously provided can be retrieved, so you can easily use the Vaporfly
            features that you customized.
          </p>
          <p>
            You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you
            can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies,
            you may not be able to fully experience the interactive features of the Vaporfly services or websites you
            visit.
          </p>
          <h3>SECURITY OF YOUR PERSONAL INFORMATION</h3>
          <p>
            Vaporfly secures your personal information from unauthorized access, use, or disclosure. Vaporfly uses the
            following methods for this purpose:
          </p>
          <ul>
            <li>SSL Protocol</li>
          </ul>
          <p>
            When personal information (such as a credit card number) is transmitted to other websites, it is protected
            through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We strive to take
            appropriate security measures to protect against unauthorized access to or alteration of your personal
            information.
          </p>
          <p>
            Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100%
            secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there
            are security and privacy limitations inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data exchanged between you and us through
            this Site cannot be guaranteed.
          </p>
          <h3>CHILDREN UNDER THIRTEEN</h3>
          <p>
            Vaporfly does not knowingly collect personally identifiable information from children under the age of
            thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use
            this website.
          </p>
          <h3>EMAIL COMMUNICATIONS</h3>
          <p>
            From time to time, Vaporfly may contact you via email for the purpose of providing announcements,
            promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve
            our Services, we may receive a notification when you open an email from Vaporfly or click on a link therein.
          </p>
          <p>
            If you would like to stop receiving marketing or promotional communications via email from Vaporfly, you may
            opt out of such communications by sending an email to consumer(at)vaporfly.com requesting that they be
            removed from the Vaporfly email list.
          </p>
          <h3>CHANGES TO THIS STATEMENT</h3>
          <p>
            Vaporfly reserves the right to change this Privacy Policy from time to time. We will notify you about
            significant changes in the way we treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy
            information on this page. Your continued use of the Site and/or Services available through this Site after
            such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
            agreement to abide and be bound by that Policy.
          </p>
          <h3>CONTACT INFORMATION</h3>
          <p>
            Vaporfly welcomes your questions or comments regarding this Statement of Privacy. If you believe that
            Vaporfly has not adhered to this Statement, please contact Vaporfly at: Email Address: Vaporfly
            consumer(at)Vaporfly.com
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetaMixin from 'Lib/mixins/MetaMixin';

export default {
  name: 'PrivacyPolicy',
  mixins: [MetaMixin],
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'Privacy Policy' }]);
  },
};
</script>

<style type="text/css" lang="scss">
p {
  text-indent: 1.5em;
}
</style>
